
.center-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 7.5rem auto;
}
.center-section .title-container{
    box-shadow: 2px 2px 5px -2px var(--color--text-secondary);
    background-color: rgba(24, 18, 77,0.9);
    color: var(--color--text-secondary);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    margin: -1.5rem auto 0rem 55%;
}
.center-section .content-container{
    color: #fff;
    margin: 2rem auto;
    width: 90%;
    font-size: 1.5rem;
    text-align: justify;
}
.center-section .poster-container{
    box-shadow: 0px 0px 5px -1px var(--color--text-secondary);
    background-color: rgba(24, 18, 77,0.9);
    color: var(--color--text-secondary);
    border-radius: 0.5rem;
    padding: 2rem;
    margin: 0rem auto 2rem auto;
    width: 60%;
    text-align: center;
}