/**featured-articles*/
.featured-articles{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0% auto;
}
.featured-articles .article{
    width: 80%;
    height: 60vh;
    margin: 0.25rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.featured-articles .article:nth-child(2n){
    flex-direction: row-reverse;
}
.article .media-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40%;
    height: 100%;
}
.media-container h3{
    position: absolute;
    top: 0;
    /*background-color: #fff;*/
    /*color: #000;*/
    text-align: center;
    font-size: 2rem;
    padding: 0.5rem;
    color: var(--color--text-secondary);
}
.media-container .img-container{
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img-container img,
.img-container video{
    height: 100%;
    width: 90%;
}
.article .detail-container{
    width: 60%;
    height: 100%;
    padding: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.detail-container p{
    width: 100%;
    color: #fff;
    text-align: justify;
    font-size: 1.2rem;
    padding: 0.2rem;
    line-height: 2.2rem;
}
.detail-container button{
    width: 200px;
    padding: 0.5rem;
    border-radius: 5px;
    border: none;
    background-color: rgba(0,0,0,0.4);
    color: var(--color--text-secondary);
    margin-top: 2rem;
}