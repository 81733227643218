/*Nav*/
nav{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0.5rem 4rem 0.5rem 4rem;
    width: 100%;
    height: 100px;
    top:0;
    z-index: 4;
    transition: 300ms;
    box-shadow: 0px 2px 15px 5px rgba(0,0,0,0.5);
    background-color: var(--color--primary);
}
nav.bg-active{
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0px 2px 15px 5px rgba(0,0,0,1);
    z-index: 5;
}

nav ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.2rem;
    
}
nav ul li{
    padding: 0.1rem 1rem;
    font-size: 1.5rem;    
    transition: 300ms;
}
nav ul li a span{
    color: #fff;
    transition: 300ms;
}
nav ul li:hover{
    border-radius: 0.2rem;
    background: rgba(255,255,255,0.1);
}
nav ul li:hover span{
    color: #ccb07b;
}
.logo-container{
    width: 200px;
    height: 100%;
    overflow: hidden;
}
.logo-container img{
    width: 100%;
    height: auto;
}