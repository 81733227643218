.cavinet-section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 7.5rem auto 0rem auto;    
}
.cavinet-section .title-container{
    color: #fff;
    padding: 2rem;
    margin: 0rem auto;
    text-align: center;
    width: 50%;
}
.cavinet-section .intro-container{
    color: #fff;
    margin: 2rem auto;
    width: 90%;
    font-size: 1.5rem;
    text-align: justify;    
}
.cavinet-section .cavinet-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: 0rem auto;
}
.cavinet-cards .card-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 250px;
    width: 200px;
    color: #fff;
    margin: 1rem 2rem;
    padding: 1.5rem;
    box-shadow: inset -10px 0px 60px 5px #000;
    text-align: center;
    background: rgba(47,20,107,1);

}
.cavinet-cards .name-container{
    width: 100%;
    height: 40px;
}
.cavinet-cards .card-container .img-container{
    width: 95%;
    height: 150px;
    margin: 0rem auto;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
}
.cavinet-cards .detail-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    height: 60px;
    
}