/* Reset Css*/
*, *::before, *::after {
    box-sizing: border-box;
}
* {
    margin: 0;
    padding:0px;
    list-style: none;
    text-decoration: none;
    font-family: 'Roboto',sans-serif;
    font-size: 1rem;
    font-weight: 400;
}
  
html, body {
    display: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    background-image: url('./assets/img/wallpapers/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
  
img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}
  
input, button, textarea, select {
    font: inherit;
}
  
p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}
  
#root, #__next {
    position: relative;
    isolation: isolate;
    display: block;
    width: 100%;
    height: 100vh;
    /*padding-bottom: 50px;*/
    color: #ffffff;
    overflow: auto;
    z-index: 1;
}
