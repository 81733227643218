.blog-main .center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.blog-main .blog-card{
    width: 95%;
    height: 300px;    
    padding: 2rem;
    background-color: rgba(24, 18, 77, 0.76);
    border-radius: 0rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    margin: 3.5rem auto;
}
.blog-main .left-column{
    width: 40%;
    height: 100%;
    justify-content: space-between;
}
.blog-main .img-container{
    width: 100%;
    height: 100%;
    margin-top: -3.8rem;
    
}
.blog-main .img-container img{
    width: auto;
    max-width: 100%;
    height: 100%;
    box-shadow: 0px 5px 10px -1px #9500a7;
}
.blog-main .detail-container{
    width: 60%;
    height: 100%;
    padding: 0rem 1rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
.blog-main .detail-container h2{
    color: #ccb07b;
}
.blog-main .detail-container p{
    height: 150px;
    width: 100%;
    overflow: hidden;
    word-wrap: break-word;
    text-align: justify;
}
.blog-main .detail-container hr{
    margin-top: 0.2rem;
    width: 80%;
    border: 1px solid #ccb07b;
}
.blog-main .footer-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 1rem;
    width: 100%;
    color: #fff;
}

.blog-main .footer-card h5{
    margin: 0px;
    opacity: 0.8;
    color: #fff;
}

.blog-main .user-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 0.5rem;
    width: 100%;
    color: #ccb07b;
}
.blog-main .user-container .link{
    color: #ccb07b;
    transition: 600ms;
}
.blog-main .user-container .link:hover{
    color: #fff;
}
.blog-main .view-post{
    position: absolute;
    bottom: 50%;
    right: -15px;
    width: 40px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.9);
    border-radius: 0rem 0.8rem 0.8rem 0rem;
    cursor:pointer;
    transition:300ms;
}
.blog-main .view-post svg{
    font-size: 20px;    
}
.blog-main .view-post svg path{
    color: #000;
}
.blog-main .view-post:hover svg path{
    color: #fff;
}