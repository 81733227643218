.biography-main .biography-gallery{
    width: 95%;
    margin-top: 70px;
    padding: 1rem;
    background-color: var(--color--primary);
    color: var(--color--text-secondary);    
}
.biography-main .biography-gallery h2{
    width: 100%;
    padding-bottom: 1rem;
    font-size: 1.2rem;
}
.biography-main .biography-gallery div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}
.biography-main .biography-gallery iframe{
    border: none;
    box-shadow: 0px 0px 10px -1px #ccb07b;
}