.call-button-bar{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    bottom: 0%;
    z-index: 2;
    background-color: var(--color--primary);
}
.call-button-container{
    width: 250px;
    padding: 0.5rem;
    margin: 0.5rem;    
    border: 1px solid #ccb07b;
    transform: perspective(400px) rotate(-0deg) skew(0deg) translate(0, 0);
    box-shadow: 0px 5px 5px -5px #ccb07b;
    border-radius: 0.5rem;
    cursor:pointer;
    transition: 500ms;
}
.call-button-container:hover,
.social-network-bar .social-item:hover{
    background-color: #c9b795;
}
.call-button-container:hover a span,
.call-button-container:hover a svg,
.social-network-bar .social-item:hover a svg{
    color: #000;
}
.call-button-container a{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.call-button-container a svg {
    color: #ccb07b;
    padding: 0rem;
}
.call-button-container a span{
    color: #ccb07b;
    padding-left: 0.4rem;
}
.social-network-bar{
    width: auto;
    margin: 0.5rem 0rem; 
    transform: perspective(400px) rotate(-0deg) skew(0deg) translate(0, 0);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.social-network-bar .social-item{
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 100%;
    box-shadow: 0px 0px 1px 1px #ccb07b;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 500ms;    
}
.social-item svg{
    color: #ccb07b;
}