.rituals-main .rituals-content{
    width: 95%;
    margin-top: 70px;
    padding: 1rem 2.5rem;
    background-color: var(--color--primary);
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.rituals-main .rituals-content .ritual-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    max-width: 365px;
    position: relative;
    cursor: pointer;
    transform: none;
    transition: 1200ms;

}
.rituals-main .rituals-content .ritual-card h2{
    padding: 1rem;
    text-align: center;
    width: 80%;
    position: absolute;
    top: 60%;
    background-color: rgba(24, 18, 77, 0.9);
    color: var(--color--text-secondary)
}
.rituals-main .rituals-content .ritual-card img{
    padding: 1rem;
    width: 90%;
    box-shadow: 0px 0px 12px -4px #ccb07b;
}
.rituals-main .rituals-content .ritual-card.active{
    transform: rotateY(180deg);
}
.rituals-main #description.active{
    opacity: 1 !important;
    z-index: 2 !important;
    height: 90vh !important;
    width: 100%;
    overflow-x: hidden;
    margin: auto 0px;
}
.rituals-main #description{
    position: absolute;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(24, 18, 77);
    width: 100%;
    opacity: 0;
    z-index: 1;
    height: 0%;
    transition: 1200ms;
}
.rituals-main #description .description-content{
    width: 90%;
    margin-top: 70px;
    padding: 1rem 2.5rem;    
    color: #fff;
    overflow: auto;
    position: relative;
}
.rituals-main #description .description-content h2{    
    padding: 1rem 1rem 0rem 0rem;
    width: 100%;
    text-align: left;
    color: #ccb07b;
}
.rituals-main #description .description-content hr{    
    border-color: #ccb07b;
    margin: 1rem 0rem;
    width: 80%;
}
.rituals-main #description .description-content img{
    float: left;
    padding: 0rem 1rem 1rem 0rem;
}
.rituals-main #description .description-content p{
    font-size: 1.2rem;
    text-align: justify;
    padding: 0.5rem 0rem;
}
.rituals-main #description .description-content .call-button-container{
    width: auto;
    margin: 1rem auto;
}
.rituals-main #description .description-content #close{
    position: absolute;
    top: 0rem;
    right: 2rem;
    color: #ccb07b;
    cursor: pointer;
}
.hidden-mod{
    height: 10px;
    overflow: hidden;
}
