.post-main .post-content{
    margin: 7rem auto 0rem auto;
    background-color: var(--color--primary);
    color: #fff;
    position: relative;
    padding: 4rem;
}
.post-main .post-content h1{
    background-color: rgba(24, 18, 77, 0.9);
    box-shadow: 0px 0px 15px -4px var(--color--text-secondary);
    color:var(--color--text-secondary);
    width: 50%;
    padding: 0.5rem;
    top: -2rem;
    right: 8rem;
    position: absolute;
    text-align: center;
    font-size: 1.5rem;
}
.post-main .post-content p{
    font-size: 1.2rem;
    font-weight: 400;
    text-align: justify;
}
.post-content .blog-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 1.8rem auto;
    box-shadow: 10px 10px 10px -8px var(--color--text-secondary);
    padding-bottom: 0.5rem;
}
.post-content .blog-head h3{
    width: 57%;
    margin-left: 2%;
    border-left: 2px solid #ccb07b;
    padding: 2rem 4rem;
    font-size: 2rem;
    font-weight: 400;
    
}
.post-content .blog-head .img-container{
    width: 40%;
    height: 350px;
}
.post-content .blog-head .img-container img{
    max-width: 100%;
    width: auto;
    height: 100%;
}
