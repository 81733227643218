.biography-main .biography-content{
    width: 95%;
    padding: 1rem 2.5rem;
    background-color: var(--color--primary);
    color: #fff;
}
.biography-main .biography-content h1{
    width: 100%;
    padding: 0rem 0rem 1rem 0rem;
    font-size: 1.2rem;
    color: var(--color--text-secondary)
}
.biography-main .biography-content img{
    width: auto;
    height: 65vh;
    padding: 0rem 1rem 1rem 0rem;
}
.biography-main .biography-content p{
    font-size: 1.1rem;
    text-align: justify;
}
