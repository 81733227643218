.angels-main .card-section{
    padding-left: 4rem;
    width: 70%;
    height: 280px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    opacity: 1;
    transition: 500ms;
}
.angels-main .card-section .angel-card{
    width: 80%;
    height: 80%;
    color: #000;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    position: absolute;    
    opacity: 0.7;
    top: 10%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0.5rem;
    transition: 500ms;
}
.angels-main .card-section .angel-card.active{
    opacity: 1;
    height: 100%;
    top: 0;
    left: 10%;
    right: 10%;
    z-index: 3;
}
.angels-main .card-section .angel-card.back{
    left: 0;
    right: 10%;
    z-index: 2;
}
.angels-main .card-section .angel-card.next{
    right: 0;
    z-index: 2;
}
.angels-main .card-section .angel-card .img-container{
    width: 40%;
    height: 100%;
}
.angels-main .card-section .angel-card .detail{
    width: 60%;
    height: 100%;
    padding: 0rem 1rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: justify;
}
.angels-main .card-section .angel-card .detail hr{
    width: 80%;
    border: 1px solid #ccb07b;
}
.angels-main .card-section .angel-card .detail h2{
    font-size: 1.2rem;
    color: var(--color--text-secondary)
}
.angels-main .card-section .angel-card .detail .view-more-button{
    font-size: 1rem;
    background-color: var(--color--text-secondary);
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    transition: 1s;
}
.angels-main .card-section .angel-card .detail .view-more-button:hover{
    background-color: var(--color--primary);
}
.angels-main .card-section .angel-card img{
    width: 100%;
    height: 100%;
}
.angels-main .post-section{
    width: 95%;
    height: 80vh;
    padding: 1rem;
    background-color: var(--color--primary);
    color: #fff;
    opacity: 1;
    transition: 300ms;
    
}
.angels-main .angels-cr-pager{
    margin-top: 1rem;
    width: auto;
    padding: 0.5rem;
    background-color: rgba(24, 18, 77);
    color: #ccb07b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    opacity: 1;
    transition: 500ms;
}
.angels-main .angels-cr-pager .icon-container{
    margin: 0.5rem 1rem;
    cursor: pointer;
    transition: 300ms;
}
.angels-main .angels-cr-pager .icon-container:hover,
.angels-main .angels-cr-pager .nb-container span:hover,
.angels-main .angels-cr-pager .nb-container span.active{
    color: #fff;
}
.angels-main .angels-cr-pager .nb-container span{
    padding: 0.5rem;
    cursor: pointer;
    transition: 300ms;
}
.angels-main .post-section .post-content{
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.angels-main .post-section .post-content .header{
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: var(--color--text-secondary);
}
.angels-main .post-section .post-content .header h2{    
    font-size: 1.2rem;
}

.angels-main .post-section .post-content .detail{
    height: 100%;
    overflow: auto;
    padding: 0.5rem;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.angels-main .post-section .post-content .detail p{
    padding: 0.5rem 0.2rem;
}
