/*biography*/
.biography-main .biography-content .social-bar{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}
.biography-main .biography-content .social-bar .footer-item{
    position:relative
}
.biography-main .biography-content .social-bar svg{
    width: 25px;
    height: 25px;
    margin: 0.5rem 0.8rem;    
    color: #ccb07b;
    position: relative;
    
}
.biography-main .biography-content .social-bar span{
    position: absolute;
    font-size: 0.8rem;
    top: -2px;
    right: -2px;
    display: none;
}
/*carousel*/
.angels-main .card-section .angel-card .detail .social-bar{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.angels-main .card-section .angel-card .detail .social-bar svg{
    width: 15px;
    height: 15px;
    margin: 0.5rem 0.8rem;    
    color: #000;
    position: relative;
}
.angels-main .card-section .angel-card .detail .social-bar span{
    position: absolute;
    font-size: 0.8rem;
    top: -2px;
    right: -2px;
    display: none;
}
/*angels article*/
.angels-main .post-section .post-content .header .social-bar{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}
.angels-main .post-section .post-content .header .social-bar .return{
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;
    background-color: rgba(255,255,255,0.96);
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    transition: 300ms;
}
.angels-main .post-section .post-content .header .social-bar .return:hover{
    background-color: var(--color--text-secondary);
    color: #fff;
}
.angels-main .post-section .post-content .header .social-bar svg{
    width: 15px;
    height: 15px;
    margin: 0.5rem 0.8rem;    
    color: #fff;
    position: relative;
}
.angels-main .post-section .post-content .header .social-bar span{
    position: absolute;
    font-size: 0.8rem;
    top: -2px;
    right: -2px;
    display: none;
}
/*Post card1*/
.blog-main .social-bar{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
.blog-main .footer-card h5{
    margin: 0px;
    opacity: 0.8;
    color: #fff;
}
.blog-main .footer-item{
    padding: 0.5rem 0rem 0rem 0.5rem;
    cursor: pointer;
    color: #fff;
}
.blog-main .footer-item svg{
    width: 15px;
    height: 15px;
    margin: 0.5rem 0.8rem; 
    color: #fff;
    position: relative;
}
.blog-main .footer-item span{
    position: absolute;
    font-size: 0.8rem;
    top: -2px;
    right: -2px;
    display: none;
}
/*Post*/
.post-content .social-bar{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5rem;
}
.post-content .social-bar svg{
    width: 15px;
    height: 15px;
    margin: 0.5rem 0.8rem; 
    color: #fff;
    position: relative;
    cursor: pointer;
}
.post-content .footer-item span{
    position: absolute;
    font-size: 0.8rem;
    top: -2px;
    right: -2px;
    display: none;
}
