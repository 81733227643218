/*Global var*/
:root {    
    --color--primary: rgba(24, 18, 77, 0.76);
    --color--secondary: rgba(100,100,100,0.8) ;
    --color--text-primary: rgba(255, 255, 255, 1);
    --color--text-secondary: #ccb07b;
    --color--button-success: #89A621;
    --bs-link-color: #3297c9;
}
header{
    width: 100%;
    height: 65%;
    /*margin: 100px auto 0px auto;*/
    background-image: url('./assets/img/backgrounds/bg_16.png');
    background-color: var(--color--primary); 
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    z-index: 1;
}
.header-overlay{
    width: 50%;
    height: 35%;
    text-align: center;
    font-size: 2rem;
    color: #ccb07b;
    position: absolute;
    bottom: -15%;
    background-color: rgba(24, 18, 77);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.2rem;
    box-shadow: inset 0px 0px 8px 1px #ccb07b;
}
main{
    min-height: 100vh;
    background-color: rgba(24, 18, 77,0.1); 
    padding-top: 5%;
    margin-bottom: 20px;
}
main section{
    background-color: rgba(24, 18, 77,0.7);
}
.biography-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.angels-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.rituals-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
}
.hidden{
    opacity: 0 !important;
    height: 0vh !important;
    z-index: 1 !important;
    line-height: 0 !important;
    margin: 0 !important;
}
  